<template>
  <div>
    <b-modal
      id="edit"
      size="xl"
      header-bg-variant="primary"
      header-text-variant="light"
      title="Editar"
      ref="edit"
      hide-footer
      v-if="hierarchy[index]"
    >
      <label for="name">Nome:</label>
      <b-input-group>
        <b-form-input id="name" v-model="hierarchy[index].name"></b-form-input>
      </b-input-group>
      <b-button class="mr-3 mt-3" variant="success" @click="update"
        ><i class="fa fa-save"></i> Salvar</b-button
      >
      <b-button class="mr-3 mt-3" @click="$bvModal.hide('edit')"
        >Cancelar</b-button
      >
    </b-modal>

    <b-button
      @click="loadPrevious"
      variant="primary"
      style="border-radius:0;"
      v-if="model.getPrev(level)"
    >
      <i class="fa fa-chevron-left"></i>
    </b-button>
    <div v-if="!empty">
      <h1>{{ model.getTitle(level) }}</h1>
      <b-row>
        <b-card
          v-for="(item, index) in hierarchy"
          :title="item.name"
          :key="`hirarchy_${item._id}`"
          class="col-md-3 col-sm-12 mr-5 hierarchy-card mb-3"
        >
          <b-button @click="edit(index)" variant="primary" class="button-edit">
            <i class="fa fa-edit"></i>
          </b-button>
          <b-button
            variant="outline-primary"
            @click="loadNext(item._id, level)"
            v-if="nextLabel"
            ><i :class="nextIcon" aria-hidden="true"></i>
            {{ nextLabel }}</b-button
          >
          <b-button class="float-right" @click="remove(item._id)"
            ><i class="fa fa-trash" aria-hidden="true"></i> Remover
          </b-button>
        </b-card>
      </b-row>
      <!-- </div> -->
    </div>
    <div v-else>
      <div class="alert alert-danger">
        <p>
          {{ model.getTitle(level) }} não encontrados(as) ou sem {{ nextLabel }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Hierarchy from '@/models/Hierarchy'

export default {
  computed: {
    nextLabel() {
      return Hierarchy.getTitle(Hierarchy.getNext(this.level))
    },
    nextIcon() {
      return Hierarchy.getIcon(Hierarchy.getNext(this.level))
    },
    empty() {
      return this.hierarchy.length === 0
    },
    hierarchy() {
      return this.$store.getters['hierarchies/current']
    },
    level() {
      return this.$store.getters['hierarchies/level']
    },
    user() {
      return this.$store.getters['user/current']
    }
  },
  data() {
    return {
      index: 0,
      model: Hierarchy
    }
  },
  async created() {
    this.load()
  },
  methods: {
    back() {
      this.$route.pop()
    },
    edit(index) {
      this.index = index
      this.$bvModal.show('edit')
    },
    load() {
      this.$store.dispatch('hierarchies/load', {
        user: this.user.id,
        level: this.level
      })
    },
    loadNext(_id, level) {
      this.$store.dispatch('hierarchies/navigate', { _id: _id, level: level })
      this.load()
    },
    loadPrevious() {
      this.$store.dispatch(
        'hierarchies/changeLevel',
        this.model.getPrev(this.level)
      )
      this.load()
    },
    async remove(_id) {
      await this.$store.dispatch('hierarchies/remove', _id)
      await this.load()
    },
    async update() {
      await this.$store.dispatch(
        'hierarchies/update',
        this.hierarchy[this.index]
      )
      await this.load()
      this.$bvModal.hide('edit')
    }
  }
}
</script>
<style>
.hierarchy-card .button-edit {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0;
}
</style>
