export default {
  getTitle(key) {
    switch (key) {
      case 'companies':
        return 'Empresas'
      case 'branches':
        return 'Unidades'
      case 'sectors':
        return 'Setores'
      case 'roles':
        return 'Cargos'
      case 'products':
        return 'Produtos'
      default:
        return ''
    }
  },
  getNext(key) {
    switch (key) {
      case 'companies':
        return 'branches'
      case 'branches':
        return 'sectors'
      case 'sectors':
        return 'roles'
      case 'roles':
        return 'products'
      case 'products':
        return undefined
    }
  },
  getPrev(key) {
    switch (key) {
      case 'companies':
        return undefined
      case 'branches':
        return 'companies'
      case 'sectors':
        return 'branches'
      case 'roles':
        return 'sectors'
      case 'products':
        return 'roles'
    }
  },
  getIcon(key) {
    switch (key) {
      case 'branches':
        return 'fa fa-building'
      case 'sectors':
        return 'fa fa-puzzle-piece'
      case 'roles':
        return 'fa fa-id-card'
      case 'products':
        return 'fa fa-archive'
      default:
        return ''
    }
  }
}
